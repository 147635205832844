import React, { useEffect, useRef } from "react";
import LogoBlock from "../components/logoBlock";
import { Box, Form, Grid, ResponsiveContext } from "grommet";
import { usePageMargin } from "../helpers/hooks";
import styled from "styled-components";
import { gsap } from "gsap";

const Text = styled.p`
  color: white;
  margin: 0;
  font-size: 12px;
  display: block;
`;

const LeftText = styled.div`
  height: 400px;
  text-transform: uppercase;
  width: 12px;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 50%;
  left: calc((80px - 12px) / 2);
  transform: translateY(-50%);
  writing-mode: vertical-rl;
  transform-origin: center;
  color: white;
  text-align: center;
`;

const RightText = styled.div`
  height: 400px;
  width: 12px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 50%;
  right: calc((80px - 12px) / 2);
  transform: translateY(-50%) rotate(-180deg);
  writing-mode: vertical-rl;
  transform-origin: center;
  color: white;
  text-align: center;
  /* text-orientation: sideways-right; */
`;

const BottomText = styled.div`
  height: 12px;
  width: 400px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  left: 50%;
  bottom: calc((80px - 12px) / 2);
  transform: translateX(-50%);
  color: white;
  text-align: center;
`;

const PagerContainer = styled.div`
  height: 400px;
  width: 12px;
  position: absolute;
  top: 50%;
  right: calc((80px - 12px) / 2);
  transform: translateY(-50%);
  transform-origin: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Indicator = ({ active = false }) => {
  return (
    <Box margin={{ vertical: "15px" }} style={{ border: "1px solid white" }} round="full" width="10px" height="10px" justify="center" align="center">
      {active && <Box background="white" round="full" width="10px" height="10px"></Box>}
    </Box>
  );
};
const Pager = ({page}) => {
  return (
    <PagerContainer>
      <Indicator active={page === 0}/>
      <Indicator active={page === 1}/>
      <Indicator active={page === 2}/>
      <Indicator active={page === 3}/>
      <Indicator active={page === 4}/>
    </PagerContainer>
  );
};

export const Loader = ({ percent = 50 }) => {

  return (
    <Box style={{ position: "absolute", bottom: 40, left: 40, width: "calc(100% - 80px)" }} pad={{ horizontal: "0px" }}>
      <Box style={{ position: "relative", width: "100%" }} height="2px" background="rgba(0,0,0,0.5)" margin={{ horizontal: "0px" }}>
        <Box style={{ position: "absolute", top: 0, left: 0, width: `${percent}%` }} height="2px" background="white" />
      </Box>
    </Box>
  );
};

const Gui = ({ bottom = false, pager = false, loader = false, progress = null, page=null }) => {
  return (
    <>
      <LogoBlock background="black" style={{ position: "absolute", top: 10, left: 0, right: 0, margin: "0 auto" }} />
      <LeftText>Adidas Sportswear F/W22</LeftText>
      {pager ? <Pager page={page}/> : <RightText>Adidas Sportswear F/W22</RightText>}
      {bottom && <BottomText>Adidas Sportswear F/W22</BottomText>}
      {loader && <Loader />}
    </>
  );
};

export default Gui;
