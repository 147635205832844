import React, { useContext, useEffect, useState, useRef } from "react";
import Gui, { Loader } from "../components/Gui";
import { Box, Heading, ResponsiveContext } from "grommet";

import { gsap } from "gsap";

import { useSelector, useDispatch } from "react-redux";
import { useMousePosition, useWindowDimensions } from "../helpers/hooks";

import loaderBg_1 from "../images/loaders/bg_1.jpg";
import loaderBg_2 from "../images/loaders/bg_2.jpg";
import loaderBg_3 from "../images/loaders/bg_3.jpg";
import loaderBg_4 from "../images/loaders/bg_4.jpg";
import loaderBg_5 from "../images/loaders/bg_5.jpg";

import loaderImage_1 from "../images/loaders/img_1.jpg";
import loaderImage_2 from "../images/loaders/img_2.jpg";
import loaderImage_3 from "../images/loaders/img_3.jpg";
import loaderImage_4 from "../images/loaders/img_4.jpg";
import loaderImage_5 from "../images/loaders/img_5.jpg";

import { initialLoad, moveForward, moveBack, loaded } from "../state/globalSlice";

const AnimatedTitle = ({ title, appear, noSubtitle = false, centered }) => {
  return (
    <Box
      fill={true}
      style={{
        top: 0,
        left: centered ? 0 : "8vw",
        position: "absolute",
        pointerEvents: "none",
        zIndex: 99999,
      }}
      justify="center"
      align="start">
      <Box flex="shrink" height={"80%"} justify="center" alignSelf={centered ? "center" : "start"}>
        <div className={`text-wipe ${appear !== null ? appear : null}`} style={{ textAlign: centered ? "center" : "left" }}>
          {title &&
            title.map((line, key) => {
              return <TextLine text={line} key={key} small={!noSubtitle && title.length - 1 === key} />;
            })}
        </div>
      </Box>
    </Box>
  );
};

const TextLine = ({ text, small = false }) => {
  const size = useContext(ResponsiveContext);

  return (
    <li data-animation={"to-top"}>
      <p
        className={"title-text"}
        data-text={text}
        style={{
          marginTop: small ? "7vh" : 0,
          fontSize: size === "xxsmall" || size === "xsmall" || size === "small" || size === "medium" ? (small ? "7vw" : "16vw") : small ? "12vh" : "21vh",
        }}>
        {text}
      </p>
    </li>
  );
};

const LoadingScreen = ({ title, image, backgroundImage, scene, callback }) => {
  const { loading, sceneNumber } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [appearIn, setAppearIn] = useState(null);
  const [loaderPercent, setLoaderPecentage] = useState(0);
  const loader = { per: 0 };

  const containerRef = useRef();

  const position = useMousePosition();
  const { height, width } = useWindowDimensions();

  const [foregroundRotateStyle, setForeGroundRotateStyle] = useState();

  const constrain = 300;

  useEffect(() => {
    let box = containerRef.current.getBoundingClientRect();
    let calcX = -(position.y - box.y - box.height / 2) / constrain;
    let calcY = (position.x - box.x - box.width / 2) / constrain;
    setForeGroundRotateStyle("perspective(600px) translate3D(0, 0, 1px)" + "  rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) ");
  }, [position, height, width]);

  useEffect(() => {
    sceneNumber === scene && loading ? setAppearIn("in") : setAppearIn(null);
    sceneNumber === scene &&
      loading &&
      gsap.to(loader, {
        per: 100,
        delay: 1,
        duration: 5,
        onUpdate: () => {
          setLoaderPecentage(loader.per);
        },
        onComplete: () => {
          dispatch(loaded());
        },
      });
  }, [sceneNumber]);

  return (
    <Box
      ref={containerRef}
      fill
      style={{
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
      }}>
      <AnimatedTitle title={title} appear={appearIn} />
      <Box
        width={"100vh"}
        height={"70vh"}
        round="40px"
        style={{
          right: "8vw",
          position: "absolute",
          top: 0,
          bottom: 0,
          margin: "auto",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          transform: foregroundRotateStyle,
        }}></Box>
      <Loader percent={loaderPercent} />
    </Box>
  );
};

export const Loader_1 = () => {
  return <LoadingScreen scene={1} image={loaderImage_1} backgroundImage={loaderBg_1} title={["OPENING &", "STRATEGY", "AIMEE ARANA"]} />;
};

export const Loader_2 = () => {
  return <LoadingScreen scene={2} image={loaderImage_2} backgroundImage={loaderBg_2} title={["CREATIVE", "VISION", "CARLY TATIBOUET"]} />;
};

export const Loader_3 = () => {
  return <LoadingScreen scene={3} image={loaderImage_3} backgroundImage={loaderBg_3} title={["FW22 Story /", "Activation plan", "Ole Westerholt"]} />;
};

export const Loader_4 = () => {
  return <LoadingScreen scene={4} image={loaderImage_4} backgroundImage={loaderBg_4} title={["FW22 Product", "walkthrough", "Rob Lee "]} />;
};

export const Loader_5 = () => {
  return <LoadingScreen scene={5} image={loaderImage_5} backgroundImage={loaderBg_5} title={["FW22", "Distribution", "Sascha Kreutzer"]} />;
};

export const ThankYou = () => {
  return (
    <Box fill background="black">
      <AnimatedTitle title={["THANK YOU", "FOR WATCHING"]} noSubtitle={true} centered appear={"in"} />
    </Box>
  );
};

export default Loader_1;
