import React from "react"
import ScaleLoader from "react-spinners/ScaleLoader"
import styled from "styled-components"
import IconFile from "../images/ic_file.inline.svg"
import { Box, Text } from "grommet"

const Button = styled.button`
  background-color: ${props => props.color};
  color: ${props => (props.color === "white" ? "black" : "white")};
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}` || "100%"};
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  border: 0;
  cursor: pointer;
  /* clip-path: ${props =>
    `polygon(0px ${props.height * 0.4 || 20}px, ${
      props.height * 0.4 || 20
    }px 0px, 100% 0, 100% ${props.height * 0.6 || 20}px, calc(${
      props.width
    } - ${props.height * 0.4 || 20}px) ${props.height}px, 0 100%)`}; */
  /* left: ${props => `${props.margin}px`};
  right: ${props => `${props.margin}px`}; */

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.9;
    cursor: default;
  }

  &:hover {
    background-color: #fb3308;
  }
`

const ButtonComponent = React.forwardRef(
  (
    {
      style,
      width,
      margin,
      loading,
      background,
      height = 48,
      text,
      icon = false,
      onClick,
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        onClick={onClick}
        margin={margin}
        color={background}
        height={height}
        width={width || 300}
        style={{ ...style }}
        {...rest}
      >
        {loading ? (
          <ScaleLoader
            color={"white"}
            loading={loading}
            height={10}
            width={1}
          />
        ) : (
          <Box justify={"center"} direction="row" pad={{ horizontal: "15px" }}>
            {text && (
              <Box
                justify={"center"}
                flex="grow"
                align={icon ? "start" : "center"}
              >
                <Text style={{fontSize: 12}}>{text}</Text>
              </Box>
            )}
            {icon && (
              <Box
                justify={"center"}
                flex="grow"
                align={text ? "end" : "center"}
              >
                {icon}
              </Box>
            )}
          </Box>
        )}
      </Button>
    )
  }
)

export default ButtonComponent
