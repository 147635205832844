import React, { useEffect, useState } from "react";
import { useVideojs } from "react-videojs-hook";
import { Box, Paragraph, Button } from "grommet";
import { PlayFill, FormPrevious } from "grommet-icons";

import Volume from "../images/videoControls/ic_volume_up.inline.svg";
import Mute from "../images/videoControls/ic_volume_mute.inline.svg";
import PauseFill from "../images/videoControls/pause.inline.svg";
import { useSelector, useDispatch } from "react-redux";
import { initialLoad, moveForward, moveBack, loaded, mute } from "../state/globalSlice";
import moment from "moment";

import "video.js/dist/video-js.css";

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const VideoScreen = ({ play }) => {
  const { sceneNumber, loading } = global;
  const dispatch = useDispatch();
  const { muted } = useSelector((state) => state.global);

  const [currentProgress, setCurrentProgress] = useState({
    percent: "0%",
    currentTime: "00:00",
    totalTime: "00:00",
  });

  const [paused, setPaused] = useState(false);
  // const [muted, setMuted] = useState(false);

  const onPlay = (currentTime) => {
    setPaused(false);
  };

  const onPause = (currentTime) => {
    console.log("PAUSING,,,");
    setPaused(true);
  };

  const onEnd = (currentTime) => {
    setPaused(true);
    dispatch(moveForward());
  };

  const onTimeUpdate = (currentTime) => {
    setCurrentProgress({
      percent: `${(currentTime / vjsRef.current.duration) * 100}%`,
      currentTime: moment.duration(currentTime, "seconds").format("mm:ss", { trim: false }),
      totalTime: moment.duration(vjsRef.current.duration, "seconds").format("mm:ss", { trim: false }),
    });
  };

  const { vjsId, vjsRef, vjsClassName, player } = useVideojs({
    src: "https://d21399uemq08qe.cloudfront.net/7a5bfd10-7f11-4068-bfcf-f11e0c75e89d/AppleHLS1/TMC_612_SPORTSWEAR MOODREEL_ADIDAS_JULY EDIT_FINAL EXPORT.m3u8",
    controls: false,
    autoplay: false,
    responsive: true,
    muted: false,
    bigPlayButtonCentered: true,
    fluid: true,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
  });

  useEffect(() => {
    console.log(player);
    player && player.currentTime(0);
    play && playVideo();
  }, [play]);

  const playVideo = () => {
    vjsRef.current.play();
  };

  const pauseVideo = () => {
    vjsRef.current.pause();
  };

  const muteToggle = () => {
    dispatch(mute())
  };

  useEffect(()=>{
    vjsRef.current.muted = muted;
  },[muted])

  const nextSection = () => {
    pauseVideo();
    dispatch(moveForward());
  };

  const prevSection = () => {
    pauseVideo();
    dispatch(moveBack());
  };

  return (
    <>
      <Box fill background="black" pad="80px" style={{ position: "relative" }}>
        <Box background="black" flex justify="center" style={{ position: "relative", overflow: "hidden" }}>
          <div data-vjs-player width="100%" height="100%">
            <video width="100%" height="100%" ref={vjsRef} id={vjsId} className={vjsClassName}></video>
          </div>
        </Box>
        <Box
          className="bottom-bar"
          style={{ position: "absolute", bottom: 5, left: 80, width: "calc(100% - 160px)", zIndex: 1001 }}
          height="60px"
          direction="row"
          align="center"
          justify="center">
          <Button margin={{ right: "20px" }} className="text-button noselect" plain label="BACK" onClick={prevSection} />
          {!paused ? <PauseFill onClick={pauseVideo} color="white" /> : <PlayFill size={"13px"} onClick={playVideo} color="white" />}
          <Box style={{ position: "relative", width: "100%" }} height="2px" background="#666666" margin={{ horizontal: "20px" }}>
            <Box style={{ position: "absolute", top: 0, left: 0 }} width={currentProgress.percent} height="2px" background="white" />
          </Box>
          <Box className="video-time-container" align="center" justify="center">
            <Paragraph className="video-time noselect">{`${currentProgress.currentTime} | ${currentProgress.totalTime}`}</Paragraph>
          </Box>
          <Box style={{ position: "relative" }} margin={{ horizontal: "20px" }}>
            {!muted ? (
              <Volume onClick={muteToggle} color="white" style={{ top: 1, position: "relative" }} />
            ) : (
              <Mute onClick={muteToggle} color="white" style={{ top: 1, position: "relative" }} />
            )}
          </Box>
          <Button className="text-button noselect" plain label="SKIP" onClick={nextSection} />
        </Box>
      </Box>
      {/* <Gui pager/> */}
    </>
  );
};

export default VideoScreen;
