import { Box, Text, TextInput } from "grommet"
import React from "react"


const FormFieldComponent = ({
  name,
  instructions,
  setErrors = null,
  error,
  style,
  ...rest
}) => {
  
  const onChange = () => {
    setErrors && setErrors({})
  }

  return (
    <Box margin={{ top: "10px" }}>
      <Text size="xsmall" style={{ letterSpacing: 2 }}>
        {name.toUpperCase()}
      </Text>
      <Box
        margin={{ top: "1px" }}
        background="white"
        border={{ color: error ? "#FB3308" : "black", size: "1px" }}
      >
        <TextInput
          onChange={onChange}
          style={{ ...style, fontSize: "14px", fontWeight: "normal" }}
          plain={true}
          size={"small"}
          id={`text-input-${name.toLowerCase()}`}
          name={name.replace(/ /g, "").toLowerCase()}
          {...rest}
        />
      </Box>
      <Box height="20px">
        <Text
          dangerouslySetInnerHTML={{ __html: error ? error : instructions }}
          size="xsmall"
          style={{ opacity: 1, color: error ? "#FB3308" : "white" }}
        ></Text>
      </Box>
    </Box>
  )
}

export default FormFieldComponent
