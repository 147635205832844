import React from "react"
import { Box, Text } from "grommet"

const WindowPanel = ({
  children,
  height = "auto",
  width,
  title,
  color = "white",
  close,
  closeAction
}) => {
  return (
    <Box
      height={height}
      width={width}
      background={color}
      style={{
        pointerEvents: "auto",
      }}
    >
      <Box
        pad="4px"
        height="20px"
        border={{
          color: color === "white" ? "black" : "white",
          size: "1px",
          style: "solid",
          side: "bottom",
        }}
        direction={"row"}
        justify="center"
      >
        <Box align="start" flex={"grow"}>
          <Text
            size={"10px"}
            style={{ textTransform: "uppercase", letterSpacing: 1.6 }}
            color={color === "white" ? "black" : "white"}
          >
            {title}
          </Text>
        </Box>
        {close && (
          <Box align="end" role={"button"} onClick={closeAction}>
            <Text
              size={"10px"}
              style={{ textTransform: "uppercase", letterSpacing: 1.6 }}
              color={color === "white" ? "black" : "white"}
            >
              {"Close X"}
            </Text>
          </Box>
        )}
      </Box>
      <Box fill={true} pad="20px" style={{ position: "relative" }}>
        {children}
      </Box>
    </Box>
  )
}

export default WindowPanel
