import Amplify, { API } from "aws-amplify";
import { navigate } from "gatsby";
import { Box, Form, Grid, ResponsiveContext } from "grommet";
import React, { useContext, useState } from "react";
import Button from "../components/button";
import FormCheck from "../components/formCheck";
import FormField from "../components/formField";
import FormSelect from "../components/formSelect";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WindowPanel from "../components/windowPanel";
import Coutries from "../helpers/countries";
import { handleLogin, isLoggedIn } from "../services/auth";
import ThreeSixtyVideo from "../components/ThreeSixtyVideo";
import Gui from "../components/Gui";
import { usePageMargin } from "../helpers/hooks";
import { useSelector, useDispatch } from "react-redux";
import { initialLoad, moveForward, moveBack, loaded } from "../state/globalSlice";

import { logEvent } from "../helpers/events";

const background = "black";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const Registration = ({ updateUserDetails }) => {
  const size = useContext(ResponsiveContext);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState({
    firstname: "",
    lastname: "",
    company: "",
    country: "",
    nda: "",
  });

  const [errors, setErrors] = useState({});

  const validate = (form) => {
    let errors = {
      ...(!form.firstname && { firstname: "Required" }),
      ...(!form.lastname && { lastname: "Required" }),
      ...(!form.company && { company: "Required" }),
      ...(!form.country && { country: "Required" }),
      ...(!form.nda && { nda: "Required" }),
    };
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    let valid = validate(value);
    if (!valid) return;
    try {
      setLoading(true);
      await updateUserDetails(value); // !response &&
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setErrors({ firstname: "Sorry - you're not registered for this event" });
  };

  return (
    <>
      <Form value={value} onChange={(nextValue) => setValue(nextValue)} onReset={() => setValue({})} onSubmit={({ value }) => {}}>
        <Grid
          columns={{
            count: size === "small" || size === "xsmall" || size === "xxsmall" ? 1 : 2,
            size: "auto",
          }}
          gap="small">
          <Box>
            <FormField setErrors={setErrors} name={"First Name"} error={errors.firstname} />
          </Box>
          <Box>
            <FormField setErrors={setErrors} name={"Last Name"} error={errors.lastname} />
          </Box>
        </Grid>
        <Grid
          columns={{
            count: size === "small" || size === "xsmall" || size === "xxsmall" ? 1 : 2,
            size: "auto",
          }}
          gap="small">
          <Box>
            <FormField setErrors={setErrors} name={"Company"} error={errors.company} />
          </Box>
          <Box>
            <FormSelect setErrors={setErrors} name={"Country of Residence"} nameOveride={"country"} content={Coutries.map((item) => item.value)} error={errors.country} />
          </Box>
        </Grid>
        <Box direction={"row"} width={"100%"}>
          <FormCheck
            setErrors={setErrors}
            name={"Nda"}
            instructions={`I confirm that I have read, understand and accept the following <a target="_blank" href=/2020_Newsroom_PR_Playbook_Templates_NDA-no-recipient.pdf>NDA</a> and <a target="_blank" href=/4DFWD_ADI_Purpose-Specific_Privacy_Notice.pdf>Privacy Policy</a>, and acknowledge that all information is under embargo until May 5th at 09:00 CET`}
            error={errors.nda}
          />
        </Box>
      </Form>
      <Button
        color="white"
        height={48}
        width={"100%"}
        disabled={loading}
        loading={loading}
        onClick={handleSubmit}
        margin={20}
        type="submit"
        primary
        text="Register"
        background={background}
      />
    </>
  );
};

const EmailLogin = ({ loginUser, submit }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    emailaddress: "",
  });
  const [errors, setErrors] = useState({
    emailaddress: null,
  });

  const handleSubmit = async () => {
    let valid = validateEmail(value.emailaddress);
    if (!valid) {
      setErrors({
        emailaddress: `Please enter a valid email address.`,
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      let response = await loginUser(value.emailaddress.toLowerCase());
      if (!response) {
        setErrors({
          emailaddress: `Unfortunately, your email has not been recognised.<br/>Please contact <a style="color:#FB3308; font-weight: bold;" href="mailto:adidas4DFWD@hkstrategies.com">adidas4DFWD@hkstrategies.com</a> to request access.`,
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Form value={value} onChange={(nextValue) => setValue(nextValue)} onReset={() => setValue({})}>
        <FormField
          setErrors={setErrors}
          style={{ textTransform: "lowercase" }}
          name={"Email Address"}
          instructions={"Please use the email address your invite was sent to."}
          error={errors.emailaddress}
        />
      </Form>
      <Button
        color="white"
        height={48}
        width={"100%"}
        disabled={loading}
        loading={loading}
        onClick={handleSubmit}
        margin={20}
        type="submit"
        primary
        text="Join"
        background={background}
      />
    </>
  );
};

const Region = ({ updateUserRegion }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value) => {
    try {
      setLoading(value);
      let response = await updateUserRegion(value);
      setLoading(false);
      handleLogin(response);
      isLoggedIn() && navigate(`/app/main`);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Button
        color="white"
        loading={loading && loading === "Asia"}
        disabled={loading && loading !== "Asia"}
        onClick={() => handleSubmit({ region: "Asia" })}
        style={{ marginBottom: 10 }}
        margin={20}
        type="submit"
        primary
        text="Asia"
        width={"100%"}
        height={48}
        background={background}
      />
      <Button
        color="white"
        loading={loading && loading === "Europe"}
        disabled={loading && loading !== "Europe"}
        onClick={() => handleSubmit({ region: "Europe" })}
        style={{ marginBottom: 10 }}
        margin={20}
        width={"100%"}
        height={48}
        type="submit"
        primary
        text="Europe"
        background={background}
      />
      <Button
        color="white"
        loading={loading && loading === "Americas"}
        disabled={loading && loading !== "Americas"}
        onClick={() => handleSubmit({ region: "Americas" })}
        margin={20}
        width={"100%"}
        height={48}
        type="submit"
        primary
        text="Americas"
        background={background}
      />
    </>
  );
};

const stages = [
  {
    title: "Join",
    component: <EmailLogin />,
  },
  {
    title: "Registration",
    component: <Registration />,
  },
  {
    title: "SELECT YOUR REGION",
    component: <Region />,
  },
];

function checkUser(user) {
  const apiName = "adidasapi";
  const path = `/users/${user}`;
  return API.get(apiName, path, {});
}

function updateUser(user, userDetails) {
  const apiName = "adidasapi";
  const path = `/users/`;

  // console.log(user)
  return API.put(apiName, path, {
    body: {
      email: user,
      ...userDetails,
    },
  });
}

const SignUp = () => {
  const [pageMargin] = usePageMargin(25);

  const [registrationStage, setRegistraionStage] = useState(0);
  const [currentUser, setCurrentUser] = useState(false);
  const [capturedDetails, setCapturedDetails] = useState({});

  const loginUser = async (user) => {
    try {
      const response = await checkUser(user);
      // console.log(response)
      if (typeof response === "object" && response !== false) {
        // user is registered login
        handleLogin(response);
        //logEvent("loggedIn");
        isLoggedIn() && navigate(`/app/main`);
        return true;
      } else if (response === true) {
        // user hasn't completed registration
        response && setCurrentUser(user);
        response && setRegistraionStage(1);
        return true;
      } else {
        // user has no access
        return false;
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const updateUserDetails = async (userDetails) => {
    try {
      setCapturedDetails(userDetails);
      const response = await updateUser(currentUser, userDetails);
      response && setRegistraionStage(2);
      return response;
    } catch (err) {
      console.log(err.response);
    }
  };

  const updateUserRegion = async (userDetails) => {
    // console.log({
    //   ...capturedDetails,
    //   ...userDetails,
    // })
    try {
      const response = await updateUser(currentUser, {
        ...capturedDetails,
        ...userDetails,
      });
      if (response && response.success) {
        handleLogin({ email: currentUser, region: userDetails.region });
        isLoggedIn() && navigate(`/app/main`);
      }
    } catch (err) {
      console.log(err.response);
    }
  };
  return (
    <Box justify="center" align="center" fill={true} pad={`${pageMargin}px`} style={{ position: "absolute", top: 0, pointerEvents: "none" }}>
      <WindowPanel width={"590px"} color={"black"} title={stages[registrationStage].title}>
        {React.cloneElement(stages[registrationStage].component, {
          loginUser,
          updateUserDetails,
          updateUserRegion,
          currentUser,
        })}
      </WindowPanel>
    </Box>
  );
};

const Login = () => {
  const dispatch = useDispatch();

  const next = () => {
    dispatch(moveForward());
  };

  return (
    <Layout background={background}>
      <SEO background={background} title="Login" />
      <ThreeSixtyVideo />
      <Gui bottom />
      <SignUp />
      <Button style={{ position: "absolute", bottom: 0, right: 0 }} onClick={next} color="white" text="DEV NEXT" />
    </Layout>
  );
};
export default Login;
